import React, { useState } from 'react';
import * as maptilersdk from '@maptiler/sdk';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight, faCircleArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerPng from '../marker.png';


    
    
export default function ImagesDisplay({handleClick, documents, openPage}) {
  const [selectedPage, setSelectedPage] = useState(openPage);



  maptilersdk.config.apiKey = process.env.REACT_APP_MAPAPI;

  function changePage(pagesChange) {
    if (selectedPage + pagesChange >= 0 && selectedPage + pagesChange < documents.length) {
      setSelectedPage(prev=>prev+pagesChange);
    }
  }
  console.log(openPage);
  return (
    <>
        <FontAwesomeIcon icon={faXmark} className='closeButton' onClick={handleClick}/>
        <FontAwesomeIcon icon={faCircleArrowRight} className='arrow arrowRight' style={{color: (selectedPage < documents.length-1) ? "#ff6700ff" : '#001c55ff',}} onClick={() => changePage(1)}/>
        <FontAwesomeIcon icon={faCircleArrowLeft} className='arrow arrowLeft' style={{color: (selectedPage > 0) ? "#ff6700ff" : '#001c55ff',}} onClick={() => changePage(-1)}/>
      <div className="imagesDisplay">
        {Array.isArray(documents[selectedPage]) && documents[selectedPage].map((document) => {
          return(
            <div key={document.$id} className='imageMap'>
              <img key={document.$id} className="display--img" alt="Bild von Strassenmahlerei in Bern" src={document.imageUrl}></img>
              <MapContainer className='map' center={[document.lat, document.long]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                  url={`https://api.maptiler.com/maps/backdrop/256/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPAPI}`}
                />
                <Marker position={[document.lat, document.long]} icon={new Icon({iconUrl: markerPng, iconSize: [25, 30], iconAnchor: [12, 30]})}>
                  <Popup>
                    {document.description}
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          )
        })}
      </div>
    </>
  )  
};