import marker from '../marker.png';
import swissMapImage from '../bernBackground.png';
import React, {useState, useEffect} from 'react';



export default function ImageSelector({ handleClick, documents }) {
    const [isMapHovered, setIsMapHovered] = useState(false);
    const [position, setPosition] = useState({ x: 100, y: 70 }); // Initialize with percentages

    useEffect(() => {
        // Circular motion animation when the circle is not hovered
        if (!isMapHovered) {
          const radius = 20; // Adjust the radius as needed
          const speed = 0.002; // Adjust the speed as needed
          const centerX = 60; // X-coordinate of the center of the circle
          const centerY = 55; // Y-coordinate of the center of the circle
    
          const animationInterval = setInterval(() => {
            const angle = Date.now() * speed;
            const x = centerX + radius * Math.cos(angle);
            const y = centerY + radius * Math.sin(angle);
            setPosition({ x: `${x}%`, y: `${y}%`});
          }, 60); // Adjust the interval for smoother animation (60fps)
    
          return () => clearInterval(animationInterval);
        }
      }, [isMapHovered]);

    function handleMouseMove(e) {
        if (isMapHovered) {
        const svgRect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - svgRect.left) / svgRect.width) * 100;
        const y = ((e.clientY - svgRect.top) / svgRect.height) * 100;
        setPosition({ x: `${x}%`, y: `${y}%`});
        }
    }

    const handleCircleHover = () => {
        setIsMapHovered(true);
    };

    const handleCircleLeave = () => {
        setIsMapHovered(false);
    };
    return(
        <section className='selection--background'>
            <h1 className='selection--title'>Bilder</h1>
            <div className='selector--background'>
                <div className='selector'>
                    <div>
                        <img className='swissMap' src={swissMapImage} alt="Swiss Map" />
                        <svg className='swissMapSvg' version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 205 110" enableBackground="new 0 0 1920 1080">
                            <mask id="mask">
                                <rect width="100%" height="100%" fill="white"></rect>
                                <circle
                                    cx={position.x}
                                    cy={position.y}
                                    r={isMapHovered ? "15%" : "10%"}
                                    fill={isMapHovered ? "black" : "grey"}
                                    className={`animated-circle ${isMapHovered ? 'hovered' : ''}`}
                                />
                            </mask>
                            <path onMouseEnter={handleCircleHover} onMouseLeave={handleCircleLeave} onMouseMove={handleMouseMove} mask="url(#mask)" fill="#00072D" id="map" stroke="none" 
                                d="m 45.232447,97.459338 c -1.608953,1.745301 2.273716,8.252402 0.574152,9.951962 C 38.183502,115.03441 32.564605,97.899077 31.261417,98.224877 25.083091,99.769449 5.6704842,108.73709 0.83135987,101.47839 -1.5533262,97.901369 9.7740535,93.440104 11.135289,90.717635 13.373326,86.241561 6.6291739,80.382521 14.407336,75.715625 15.40173,75.11899 25.322358,74.429744 27.446189,74.054948 c 5.720303,-1.009461 2.815297,-6.57037 8.217059,-7.92081 33.31128,-8.327816 38.390916,-22.42865 51.865062,-25.898611 29.44563,-7.583069 48.89267,-19.670634 54.54445,-6.124289 3.00425,7.200683 3.9957,-31.3869861 12.82273,-31.004216 6.90905,0.2996009 2.16263,14.784411 3.4449,17.990098 0.99603,2.490062 12.41607,10.530931 14.73657,13.779644 3.37562,4.725869 22.17608,-13.921306 19.13841,0.574154 -1.13339,5.408418 -11.74753,4.417677 -7.08121,18.755638 4.78109,14.690634 10.98759,18.084919 14.92795,22.583309 3.94036,4.498403 -16.8534,6.089512 -20.2867,9.951973 -3.84545,4.326122 2.28782,9.457873 -2.29662,11.291641 -6.64842,2.659371 -12.69616,-9.059855 -14.73656,-11.10027 -2.34224,-2.342238 -6.18492,2.709706 -8.03812,3.6363 -1.98018,0.990096 -8.65662,-4.6043 -10.9089,-5.167365 -3.11275,-0.778193 -1.51502,6.831377 -1.91384,6.698444 -3.21079,-1.070273 -15.03999,-11.004364 -18.37286,-9.186434 -7.05753,3.849561 -6.74167,22.613146 -14.16242,19.521166 -6.12619,-2.552581 -9.764132,-9.264404 -16.267643,-11.865811"/>
                        </svg>
                    </div>
                    {Array.isArray(documents) && documents.map((document, index) => {
                        if (document[0]?.lat !== undefined && document[0]?.long !== undefined) {
                            const top = (((document[0].lat - 46.990641) / (46.921228 - 46.990641)) * 100) - 10;
                            const left = ((document[0].long - 7.29411) / (7.49571 - 7.29411)) * 100;
                            return (
                                <img
                                    key={document[0].$id}
                                    className='marker'
                                    style={{ left: `${left}%`, top: `${top}%` }}
                                    src={marker}
                                    alt="Marker"
                                    onClick={() => handleClick(index)}
                                />
                            );
                        } else {
                            // Return a placeholder or an empty component to satisfy the map function requirement
                            return null; // or return an empty <div>, <span>, etc., as appropriate for your use case
                        }
                    })}
                    <p className='selector--description'>Das ist Bern.<br/>Klicke auf eine Ortschaft in Bern</p>
                </div>
            </div>     
        </section>
    )
}