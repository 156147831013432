import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
export default function Contact() {
    return (
        <footer>
            <div>
                <FontAwesomeIcon icon={faEnvelope} style={{color: "#ff6700ff",}} />
                <a href="mailto:streetchalkart@gmail.com" className='contact--link'>streetchalkart@gmail.com</a>
            </div>
            <div>
                <FontAwesomeIcon icon={faInstagram} style={{color: "#ff6700ff",}} />
                <a href="https://www.instagram.com/zorachalk/" target="_blank" rel="noreferrer" className='contact--link'>Instagram</a>
            </div>
        </footer>
    )
}
