import React, { useState, useEffect } from 'react';
import ImageSelector from './Components/ImageSelector';
import About from './Components/About';
import Contact from './Components/Contact';
import ImagesDisplay from './Components/ImagesDisplay'
import './App.css'
import { Client, Databases, Query } from 'appwrite';

const client = new Client();
client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject(process.env.REACT_APP_PROJEKT);

  const databases = new Databases(client);

function App() {
  const [openPage, setOpenPage] = useState(0);
  const [documents, setDocuments] = useState([[]]);

  useEffect(() => {
    databases.listDocuments(
    process.env.REACT_APP_DATABASE,
    process.env.REACT_APP_COLLECTION,
    [
        Query.limit(100)
    ]
    ).then(
    function (response) {
        setDocuments(generateSortedDocuments(response.documents));
    },
    function (error) {
        console.log(error);
    }
    );
  }, []);

  function generateSortedDocuments(newDocuments) {
    let sortedDocuments = [];
    let skipp = [];

    for (let index = 0; index < newDocuments.length; index++) {
        if (!skipp.includes(index)) {
            const current = newDocuments[index];
            let sortedGroup = [current];

            for (let j = index + 1; j < newDocuments.length; j++) {
                if (!skipp.includes(j)) {
                    const compearedTo = newDocuments[j];
                    const longDiff = Math.abs(current.long - compearedTo.long);
                    const latDiff = Math.abs(current.lat - compearedTo.lat);

                    if (longDiff < 0.005 && latDiff < 0.005) {
                        sortedGroup.push(compearedTo);
                        skipp.push(j);
                    }
                }
            }
            sortedDocuments.push(sortedGroup);
        }
    }
    return sortedDocuments;
  }
  console.log(openPage);
  const handleClick = (number) => {
    setOpenPage(number);
    setShowImages(true);
  }
  const [showImages, setShowImages] = useState(false);
  return (
  <div>
      <nav>
        <ul>
          <li>
            <a href="/#bilder">Bilder</a>
          </li>
          <li>
            <a href="/#about">Über mich</a>
          </li>
          <li>
            <a href="/#contact">Kontakt</a>
          </li>
        </ul>
      </nav>
      <ImageSelector handleClick={handleClick} documents={documents}/>
      <About/>

      <section id="contact">
        <Contact />
      </section>

      {showImages && <ImagesDisplay handleClick={() => setShowImages(false)} documents={documents} openPage={openPage}/>}

    </div>
  )
}
export default App;